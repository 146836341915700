* {
  transition: color 0.2s ease-out, background-color 0.2s ease-out, fill 0.2s ease-out;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-text-fill-color: inherit !important;
    -webkit-background-clip: text !important;
}